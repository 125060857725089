/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject, Observable } from 'rxjs';

// TODO: refactor this service to use the new Storage API
@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private _storage: Storage | null = null;
  private subject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private storage: Storage
  ) {
    this.init();
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    this._storage = storage;
    this.subject.next(true);
  }

  isStorageDefined(): Observable<boolean> {
    return this.subject.asObservable();
  }


  async get<T>(key: string): Promise<T> {
    return await this._storage.get(key);
  }

  async set(key: string, value: any) {
    return await this._storage?.set(key, value);
  }

  async remove(key: string) {
    await this._storage?.remove(key);
  }


}
