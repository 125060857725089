import { DatePipe, DecimalPipe, LocationStrategy, PathLocationStrategy, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import locale from '@angular/common/locales/es-CL';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule, PERSISTENCE } from '@angular/fire/compat/auth';
import { AngularFireRemoteConfigModule, SETTINGS } from '@angular/fire/compat/remote-config';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import * as dayjs from 'dayjs';
import { ClipboardModule } from 'ngx-clipboard';
import { LottieModule } from 'ngx-lottie';
import { NgxMaskModule } from 'ngx-mask';

import { environment } from '@env';
import { ClpCurrencyPipe } from '@common/pipes/clp-currency.pipe';
import { UfCurrencyPipe } from '@common/pipes/uf-currency.pipe';
import { Util } from '@common/utils/utils';
import { HttpInterceptorProvider } from '@providers/http-interceptor/http-interceptor';
import { FirebaseService } from '@services/firebase/firebase.abstract';
import { FirebaseMockedService } from '@services/firebase/firebase.service.mock';
import { FirebaseServiceImplemented } from '@services/firebase/firebase.service';
import { ReferredService } from '@services/referred/referred.service';
import { StorageService } from '@services/storage/storage.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

registerLocaleData(locale);
dayjs.locale('es');

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
// We need a separate function as it's required by the AOT compiler. This library is loaded on demand
const playerFactory = () => import('lottie-web');

@NgModule({
  declarations: [AppComponent],
  imports: [
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.FIREBASE_CONFIG),
    GoogleTagManagerModule.forRoot({
      id: environment.GOOGLE_TAG_MANAGER_ID
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireAuthModule,
    AngularFireRemoteConfigModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    LottieModule.forRoot({ player: playerFactory }),
    ClipboardModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-CL' },
    { provide: 'googleTagManagerCSPNonce', useValue: 'CSP-NONCE' },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    ClpCurrencyPipe,
    UfCurrencyPipe,
    DecimalPipe,
    DatePipe,
    Util,
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorProvider, multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: FirebaseService, useClass: environment.USING_MOCKS ? FirebaseMockedService : FirebaseServiceImplemented },
    { provide: PERSISTENCE, useValue: 'session' },
    { provide: SETTINGS, useFactory: () => environment.ENV !== 'production' ? { minimumFetchIntervalMillis: 10000 } : {} },
    StorageService,
    ReferredService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
