import { Injectable } from '@angular/core';
import { CanLoad, Data, Route, Router } from '@angular/router';

import { take } from 'rxjs/operators';

import { UserTypes } from '@constants/authentication.constant';
import { AuthService } from '@services/authentication/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {
  constructor(
    private authService: AuthService,
    private router: Router) {
  }

  async canLoad(route: Route): Promise<boolean> {
    const session = await this.authService.getSession().pipe(take(1)).toPromise();
    if (!session) {
      this.router.navigate(['/'], { replaceUrl: true });
      return false;
    } else {
      if (session.temporaryPassword && route.path !== 'cambiar-clave') {
        this.router.navigate(['/cambiar-clave'], { replaceUrl: true });
      }
    }
    return this.verifyAccess(session?.userType, route.data);
  }

  private verifyAccess(userTypes: UserTypes[], routeData?: Data): boolean {
    if (!userTypes || !routeData || !routeData.userRoles || !(routeData.userRoles instanceof Array)) {
      return false;
    }
    return routeData.userRoles.some((routeUserType: UserTypes) => userTypes.includes(routeUserType));
  }
}
