export const HOME_URL = '/';
export const LOGIN_URL = 'login';
export const LOGOUT_URL = 'logout';
export const ADMIN_LOGIN_URL = 'login/admin';
export const CONTRACTOR_LOGIN_URL = 'login/proveedor';
export const ONBOARDING_URL = 'registro';
export const CLIENT_HOME_URL = 'home/cliente';
export const COMPANY_HOME_URL = 'home/proveedor';
export const COMPANY_PROJECTS = 'proveedor/proyectos';
export const CONTRACTOR_DATA_URL = 'perfil/proveedor';
export const CATEGORY_SELECTOR_URL = 'categorias';
export const CONTRACTOR_CATEGORY_SELECTOR_URL = 'proveedor/categorias';
export const SIMULATION_CONTRACTOR_LIST_URL = 'categorias/:categoryId/proveedores';
export const ADD_INSPIRATION_URL = 'categorias/:categoryId/proveedores/agregar-inspiracion';
export const EDIT_INSPIRATION_URL = 'proveedor/editar-inspiracion/:id';
export const CLIENT_PROJECT_DETAILS_URL = 'cliente/proyectos/detalles';
export const CONTRACTOR_PROJECTS_URL = 'proveedor/proyectos';
export const VIEW_INSPIRATION_URL = 'categorias/:categoryId/proveedores/:contractorId/inspiraciones/:inspirationId';
export const INSPIRATIONS_CATALOG_URL = 'categorias/:categoryId/proveedores/:contractorId/inspiraciones';
export const HOW_IT_WORKS_URL = 'como-funciona';
export const ADMIN_PROJECTS_URL = 'admin/operaciones';
export const ADMIN_PAYMENTS_URL = 'admin/pagos';
export const CONTACT_FORM_URL = 'contactanos';
export const ADMIN_CREATE_USERS_URL = 'admin/usuarios';
export const ADMIN_CONTRACTOR_LIST = 'admin/proveedores';
export const RATING_AND_COMMENTS = 'proveedores/:contractorId/calificaciones';
export const CHANGE_PASSWORD_URL = 'cambiar-clave';
export const RECOVER_PASSWORD_URL = 'login/recuperar-contrasena';
export const SIMULATION_URL = 'simulacion'; // TODO: This should be replaced by MORTGAGE_SIMULATOR_URL = 'simulador-hipotecario'
export const REGISTER_FORM_URL = 'registro-cliente';
export const TERMS_URL = 'legales';
export const RRSS_URLS = {
  instagramUrl: 'https://www.instagram.com/homein_chile/',
  linkedinUrl: 'https://www.linkedin.com/company/homein-chile/',
  youtubeUrl: 'https://www.youtube.com/channel/UCHuIuPLaGwctVcRJEjuDRjQ',
};
export const BLOG_URL = 'https://blog.homein.cl/';
export const PRE_REGISTRATION_URL = 'banco-consorcio';
export const PRIVATE_CATALOG_SELECTOR_URL = 'catalogo-privado/categorias';
export const PRIVATE_SIMULATION_CONTRACTOR_LIST_URL = 'catalogo-privado/categorias/:categoryId/proveedores';
export const PRIVATE_INSPIRATIONS_CATALOG_URL = 'catalogo-privado/categorias/:categoryId/proveedores/:contractorId/inspiraciones';
export const PRIVATE_VIEW_INSPIRATION_URL =
'catalogo-privado/categorias/:categoryId/proveedores/:contractorId/inspiraciones/:inspirationId';
export const HOMEIN_PRESENTATION_VIDEO_URL = 'https://www.youtube.com/embed/pbLx39HCiI8';
export const TRANSBANK_URL = 'https://www.webpay.cl/portalpagodirecto/pages/institucion.jsf?idEstablecimiento=103956630';
export const REFERRED_URL = 'referidos';
export const ENTER_CLIENT_PROFILE = 'ingresar-perfil-cliente';
export const VIEW_CLIENT_DATA = 'proveedor/proyectos/:token';
export const CONTRACTOR_APPLICATION_FORM_URL = 'https://forms.gle/1PypXLJpLBxaMJfV9';
export const PISCINERIA_FORM = 'contacto-piscineria';
export const PROJECTS_SIMULATOR_URL = 'simulador-de-proyectos';
