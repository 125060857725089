import { Injectable } from '@angular/core';

import { SIMULATION_TOAST } from '@common/mocks/simulation-toast.mock';
import { TERMS_AND_CONDITIONS_MOCK } from '@common/mocks/terms-and-conditions.mock';
import { ILegalBasis, ISimulationInformation, ISimulationModalData, ITermsAndConditions } from '@interfaces/firestore.interface';
import { UF } from '@interfaces/payment.interface';
import { FirebaseService } from './firebase.abstract';

@Injectable()
export class FirebaseMockedService implements FirebaseService {

  public async getTermsAndConditions(): Promise<ITermsAndConditions> {
    return TERMS_AND_CONDITIONS_MOCK.success[0];
  }

  public async getSimulationInformation(): Promise<ISimulationInformation> {
    return SIMULATION_TOAST.success[0];
  }

  public async showSimulationModal(): Promise<ISimulationModalData> {
    return { creditsId: [1,2], showModal: true };
  }

  public async updateSimulationModal(): Promise<boolean> {
    return true;
  }

  public async getUF(): Promise<UF> {
    return {date: '20220405', value: 30000};
  }

  public async getLegalPDFArray(): Promise<ILegalBasis[]> {
    return [{name: 'pdf', url: 'url'}];
  }

  public async updateUF(): Promise<boolean> {
    return true;
  }

  public async showLiteClientModal(): Promise<boolean> {
    return true;
  }

  public async addRutToLiteClientModal(): Promise<boolean> {
    return true;
  }
}
