import { Component, EnvironmentInjector, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { GoogleTagManagerService } from 'angular-google-tag-manager';

import { Util } from '@common/utils/utils';
import { USER_TYPES } from '@constants/authentication.constant';
import { DEFAULT_URL_HEAD_CONFIG, URL_HEAD_CONFIG } from '@constants/global.constant';
import { PROJECTS_TEXT } from '@constants/header.constant';
import { LOGGED_ADMIN_NAV_OPTIONS, LOGGED_CONTRACTOR_ADMIN_NAV_OPTIONS, LOGGED_CONTRACTOR_NAV_OPTIONS, LOGGED_NAV_OPTIONS, NOT_LOGGED_NAV_OPTIONS } from '@constants/navigation.constant';
import { BLOG_URL, HOME_URL } from '@constants/url.constant';
import { environment } from '@env';
import { INavigationOption } from '@interfaces/navigation.interface';
import { AuthService } from '@services/authentication/auth.service';
import pkg from '../../package.json';

// eslint-disable-next-line @typescript-eslint/ban-types
declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public currentUrl: string;
  public isSignedIn = false;
  public navigateOptions: INavigationOption[] = [];
  public userName: string | null = null;
  public version = pkg.version;

  constructor(
    public environmentInjector: EnvironmentInjector,
    private authService: AuthService,
    private gtmService: GoogleTagManagerService,
    private meta: Meta,
    private router: Router,
    private title: Title,
    private translateService: TranslateService,
    private util: Util
  ) {
    this.router.onSameUrlNavigation = 'reload';
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  public async ngOnInit(): Promise<void> {
    // TODO: later on we should support more languages
    this.translateService.setDefaultLang('es-CL');
    this.authService.getSession().subscribe((session) => {
      if (session) {
        this.isSignedIn = true;
        this.userName = `${session.name} ${session.lastName}`;
        if (session.userType.includes(USER_TYPES.client)) {
          this.navigateOptions = LOGGED_NAV_OPTIONS;
        } else if (session.userType.includes(USER_TYPES.contractorAdmin)) {
          this.navigateOptions = LOGGED_CONTRACTOR_ADMIN_NAV_OPTIONS;
        } else if (session.userType.includes(USER_TYPES.contractor)) {
          this.navigateOptions = LOGGED_CONTRACTOR_NAV_OPTIONS;
        } else if (session.userType.includes(USER_TYPES.admin)) {
          this.navigateOptions = LOGGED_ADMIN_NAV_OPTIONS;
        }
      } else {
        this.isSignedIn = false;
        this.userName = null;
        this.navigateOptions = NOT_LOGGED_NAV_OPTIONS;
      }
    });
    if (window['gtag']) {
      gtag('config', environment.GOOGLE_ANALYTICS_ID);
    }
    this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationEnd) {
        this._updateHead(event.url);
        if (environment.GOOGLE_TAG_MANAGER_ID) {
          const hit = {
            event: 'page',
            pageName: event.url
          };
          this.gtmService.pushTag(hit);
        }
        if (window['gtag']) {
          gtag('config', environment.GOOGLE_ANALYTICS_ID,
            {
              /* eslint-disable quote-props */
              /* eslint-disable @typescript-eslint/naming-convention */
              'page_path': event.urlAfterRedirects
            }
          );
        }
        this.currentUrl = event.url.substring(1);
      }
    });
  }

  public isCurrentRoute(url: string): boolean {
    return url === this.currentUrl;
  }

  public goTo(url: string): void {
    if (url === BLOG_URL) {
      this.util.goToNewTab(url);
    } else if (url === PROJECTS_TEXT) {
      this.router.navigate([HOME_URL], { fragment: 'proyectos' });
    } else {
      this.router.navigate([url], { replaceUrl: true });
    }
  }

  private _updateHead(url: string): void {
    this.meta.updateTag({ name: 'robots', content: DEFAULT_URL_HEAD_CONFIG.ROBOTS });
    this.title.setTitle(URL_HEAD_CONFIG[url]?.title || DEFAULT_URL_HEAD_CONFIG.TITLE);
    this.meta.updateTag({ name: 'description', content: URL_HEAD_CONFIG[url]?.description || DEFAULT_URL_HEAD_CONFIG.DESCRIPTION });
    this.meta.updateTag({ name: 'keywords', content: URL_HEAD_CONFIG[url]?.keywords || DEFAULT_URL_HEAD_CONFIG.KEYWORDS });
    this.meta.updateTag({ name: 'og:title', content: URL_HEAD_CONFIG[url]?.og.title || DEFAULT_URL_HEAD_CONFIG.OG.TITLE });
    this.meta.updateTag({ name: 'og:description', content: URL_HEAD_CONFIG[url]?.og.description || DEFAULT_URL_HEAD_CONFIG.OG.DESCRIPTION });
    this.meta.updateTag({ name: 'og:image', content: URL_HEAD_CONFIG[url]?.og.image || DEFAULT_URL_HEAD_CONFIG.OG.IMAGE });
  }
}
