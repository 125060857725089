import { Region } from '@interfaces/credit.interface';

// TODO: we should get this data from backend or maybe Firebase RemoteConfig
export const AVAILABLE_REGIONS: Region[] = [
  { code: -1, name: 'Todas las regiones disponibles' },
  { code: 3, name: 'Región de Antofagasta' },
  { code: 1, name: 'Región de Arica y Parinacota' },
  { code: 4, name: 'Región de Atacama' },
  { code: 5, name: 'Región de Coquimbo' },
  { code: 11, name: 'Región de La Araucanía' },
  { code: 13, name: 'Región de Los Lagos' },
  { code: 12, name: 'Región de Los Ríos' },
  { code: 9, name: 'Región de Ñuble' },
  { code: 2, name: 'Región de Tarapacá' },
  { code: 10, name: 'Región del Biobío' },
  { code: 7, name: 'Región del Libertador Gral. Bernardo O’Higgins' },
  { code: 8, name: 'Región del Maule' },
  { code: 16, name: 'Región Metropolitana de Santiago' },
  { code: 6, name: 'Región de Valparaíso' },
];

// TODO: we should get this data from backend or maybe Firebase RemoteConfig
export const REGIONS: Region[] = [
  { code: 3, name: 'Antofagasta' },
  { code: 1, name: 'Arica y Parinacota' },
  { code: 4, name: 'Atacama' },
  { code: 10, name: 'Biobío' },
  { code: 5, name: 'Coquimbo' },
  { code: 11, name: 'La Araucanía' },
  { code: 7, name: 'L. Bernardo O’Higgins' },
  { code: 13, name: 'Los Lagos' },
  { code: 12, name: 'Los Ríos' },
  { code: 8, name: 'Maule' },
  { code: 9, name: 'Ñuble' },
  { code: 16, name: 'RM' },
  { code: 2, name: 'Tarapacá' },
  { code: 6, name: 'Valparaíso' },
];

export const DEFAULT_REGION_CODE = 16;

// TODO: we should get this data from backend or maybe Firebase RemoteConfig
export const BANKS = [
  'Banco BCI',
  'Banco BICE',
  'Banco Consorcio',
  'Banco Falabella',
  'Banco Itaú',
  'Banco Santander',
  'Banco Security',
  'Banco de Chile',
  'BancoEstado',
  'Scotiabank'
];

export const PROJECT_CATEGORY_IDS_SUPPORTED_BY_PROJECT_SIMULATOR = [
  1, // Pisos
  10, // Piscina - SPA
];

export const DEFAULT_URL_HEAD_CONFIG = {
  DESCRIPTION: 'HomeIn es tu mejor alternativa, para remodelar o equipar tu propiedad con la asesoría de expertos y sin sobrecargar tus finanzas familiares.',
  KEYWORDS: 'credito hipotecario, hipotecario, cierre de terraza, simulador hipotecario, hipotecario bci, remodelacion, ventanas de termopanel, remodelacion de cocinas, remodelación, cotizar hipotecario, quincho',
  OG: {
    TITLE: 'Las mejoras a tu hogar en tu hipotecario',
    DESCRIPTION: 'HomeIn es tu mejor alternativa, para remodelar o equipar tu propiedad con la asesoría de expertos y sin sobrecargar tus finanzas familiares.',
    IMAGE: 'https://storage.googleapis.com/homein-prod-public-uploads/static/og-image-default.jpg',
  },
  ROBOTS: 'index, follow',
  TITLE: 'HomeIn | Incluye en tu hipotecario las mejoras de tu hogar',
};

export const URL_HEAD_CONFIG: {
  [key: string]: {
    title: string;
    description: string;
    keywords: string;
    robots?: string;
    og: {
      title: string;
      description: string;
      image?: string;
    };
  };
} = {
  '/': {
    title: 'HomeIn | Incluye en tu hipotecario las mejoras de tu hogar',
    description: 'HomeIn es tu mejor alternativa, para remodelar o equipar tu propiedad con la asesoría de expertos y sin sobrecargar tus finanzas familiares.',
    keywords: 'credito hipotecario, hipotecario, cierre de terraza, simulador hipotecario, hipotecario bci, remodelacion, ventanas de termopanel, remodelacion de cocinas, remodelación, cotizar hipotecario, quincho',
    og: {
      title: 'Las mejoras a tu hogar en tu hipotecario',
      description: 'HomeIn es tu mejor alternativa, para remodelar o equipar tu propiedad con la asesoría de expertos y sin sobrecargar tus finanzas familiares.',
    }
  },
  '/banco-consorcio': {
    title: 'Financia tus remodelaciones | HomeIn',
    description: 'Si ya tienes un hipotecario vigente, solicita el financiamiento para dejar tu hogar como nuevo.',
    keywords: 'credito fines generales, hipotecario fines generales, ventanas termopanel, construir piscina, remodelación, remodelacion, remodelar vivienda, remodelacion de casa, remodelar depto, servicios de remodelacion',
    og: {
      title: 'Financia tus remodelaciones',
      description: 'Si ya tienes un hipotecario vigente, solicita el financiamiento para dejar tu hogar como nuevo.',
      image: 'https://storage.googleapis.com/homein-prod-public-uploads/static/og-image-banco-consorcio.jpg',
    }
  },
  '/como-funciona': {
    title: '¿Cómo funciona HomeIn? | HomeIn',
    description: 'Paso a paso para financiar las mejoras de tu hogar en tu hipotecario con nuestros bancos asociados.',
    keywords: 'homein, credito hipotecario, hipotecario, cierre de terraza, simulador hipotecario, hipotecario bci, remodelacion, remodelacion de cocinas, remodelación, cotizar hipotecario, quincho',
    og: {
      title: '¿Cómo funciona HomeIn?',
      description: 'Paso a paso para financiar las mejoras de tu hogar en tu hipotecario con nuestros bancos asociados.',
      image: 'https://storage.googleapis.com/homein-prod-public-uploads/static/og-image-how-homein-works.jpg',
    }
  },
  '/registro-cliente': {
    title: 'Cotiza tu proyecto | HomeIn',
    description: 'Podrás cotizar de forma gratuita con nuestros proveedores garantizados en más de 12 categorías.',
    keywords: 'cierre de terraza, paisajismo, remodelación, ventanas termopanel, remodelacion de cocinas, remodelacion casa, quincho, piscina, remodelar terraza, remodelar vivienda, remodelacion departamento, remodelar vivienda',
    og: {
      title: 'Cotiza tu proyecto',
      description: 'Podrás cotizar de forma gratuita con nuestros proveedores garantizados en más de 12 categorías.',
    }
  },
  '/simulacion': {
    title: 'Simula aquí tu hipotecario | HomeIn',
    description: 'Si estás comprando una propiedad, incluye en tu hipotecario un 5% extra para mejorar tu nueva propiedad.',
    keywords: 'credito hipotecario, hipotecario, simulador hipotecario, simulador de credito hipotecario, hipotecario bci, remodelación, remodelacion de casas, cotizar hipotecario',
    og: {
      title: 'Simula aquí tu hipotecario',
      description: 'Si estás comprando una propiedad, incluye en tu hipotecario un 5% extra para mejorar tu nueva propiedad.',
      image: 'https://storage.googleapis.com/homein-prod-public-uploads/static/og-image-simulation.jpg',
    }
  }
};
