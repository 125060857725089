import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';

import * as Papa from 'papaparse';
import { format } from 'rut.js';

import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import { MAX_MOBILE_WIDTH, MAX_TABLET_WIDTH } from '@constants/business.constant';
import { IGenericModal } from '@interfaces/modal.interface';
import { ITableClient } from '@interfaces/user.interface';

import * as ExcelJS from 'exceljs';

@Injectable()
/** deprecated this will be refactored **/
export class Util {

  constructor(
    private datepipe: DatePipe,
    private modalController: ModalController
  ) { }

  public async openModal(modalData: IGenericModal) {
    if (!modalData) { return; }
    modalData.primaryButtonCallback = async () => {
      this.modalController.dismiss();
    };
    const modal = await this.modalController.create({
      component: GenericModalComponent,
      backdropDismiss: false,
      cssClass: 'modal generic-modal',
      componentProps: {
        data: modalData
      }
    });
    return await modal.present();
  }

  public async fileToBase64(file: File): Promise<string> {
    return new Promise<string>((resolve) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64Data = reader.result.toString()
          .replace('data:', '')
          .replace(/^.+,/, '');
        resolve(base64Data);
      };
      reader.readAsDataURL(file);
    });
  }

  public dateTransform(date: string): string {
    return this.datepipe.transform(date, 'dd-MM-yyyy');
  }

  public generateCsv(data: any, fileName: string, summaryRows = []) {
    const header = Object.keys(data[0] as object).join(',');
    const csv = Papa.unparse({data, header});
    const blob = new Blob([...summaryRows, ...csv] as BlobPart[]);
    const date = this.dateTransform(new Date().toDateString());
    const csvFileName = `${date}-${fileName}.csv`;
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = URL.createObjectURL(blob);
    a.download = csvFileName;
    a.click();
    URL.revokeObjectURL(a.href);
    a.remove();
  }

  public generateXlsx(data: {[key: string]: any}[], fileName: string): void {
    const date = this.dateTransform(new Date().toDateString());
    const xlsxFileName = `${date}-${fileName}.xlsx`;
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
    const headers = [];
    if (data.length) {
      headers.push(...Object.keys(data[0]));
    }
    worksheet.addRow(headers);
    data.forEach((row) => worksheet.addRow(Object.values(row)));
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data],
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = xlsxFileName;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  }

  public goToNewTab(url: string): void {
    const hasHttpOrHttps = /https?:\/\//.test(url);
    let finalUrl = url;

    if (!hasHttpOrHttps) { finalUrl = 'http://' + finalUrl; }
    window.open(finalUrl, '_blank');
  }

  public isEnabledCredit(client: ITableClient) {
    return client.credit?.isAvailable && !this.isCreditExpired(client);
  }

  public isCreditExpired(client: ITableClient) {
    if(!client.credit?.expiryDate) { return false; }
    return new Date(Date.parse(client.credit?.expiryDate)).getTime() < new Date().getTime();
  }

  public isClosedCredit(client: ITableClient) {
    return !client.credit?.isAvailable && this.isCreditExpired(client);
  }

  public formatRut(userRut: string): string {
    return format(userRut);
  }

  public getDateTime(date: string) {
    return new Date(Date.parse(date)).getTime();
  }

  public get mobileView(): boolean {
    return document.body.clientWidth <= MAX_MOBILE_WIDTH;
  }

  public get tabletView(): boolean {
    return document.body.clientWidth <= MAX_TABLET_WIDTH && document.body.clientWidth >= MAX_MOBILE_WIDTH;
  }
}
