import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UI_VIEW_TYPES, UserTypes } from '@constants/authentication.constant';
import { environment } from '@env';
import { ErrorReportingService } from '@services/error-reporting/error-reporting.service';

interface Session {
  idToken: string;
  userType: UserTypes[]; // TODO: this should be changed to a roles array
  // TODO: remove fields below, a session shouldn't have this data
  email: string;
  cellphoneNumber: string;
  lastName: string;
  middleName: string;
  motherLastName: string;
  name: string;
  rut: string;
  temporaryPassword: boolean;
  termsVersion: string;
}

interface EnrollData {
  additionalAddress: string;
  communeCode: number;
  email: string;
  firstName: string;
  lastName: string;
  nationalId: string;
  password: string;
  phoneNumber: string;
  streetName: string;
  streetNumber: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private onSignIn: EventEmitter<void> = new EventEmitter();
  private onSignOut: EventEmitter<void> = new EventEmitter();

  constructor(
    private angularFireAuth: AngularFireAuth,
    private errorReportingService: ErrorReportingService,
    private http: HttpClient,
  ) {
    this.angularFireAuth.onAuthStateChanged((user) => {
      if (!user) {
        this.onSignOut.emit();
      }
    }, (error) => {
      this.errorReportingService.log('AuthService.onAuthStateChanged()', 'subscribe-on-auth-state-changed', error);
    });
    this.angularFireAuth.onIdTokenChanged( (user) => {
      if (user) {
        this.onSignIn.emit();
      }
    }, (error) => {
      this.errorReportingService.log('AuthService.onIdTokenChanged()', 'subscribe-on-id-token-changed', error);
    });
  }

  enroll(data: EnrollData): Promise<void> {
    const url = `${environment.BASE_BACKEND}/authentication/enroll`;
    return this.http.post<void>(url, data).toPromise();
  }

  public getSession(): Observable<Session> {
    return combineLatest([this.angularFireAuth.idToken, this.angularFireAuth.idTokenResult]).pipe(map(([idToken, idTokenResult]) => {
      return idTokenResult ? {
        cellphoneNumber: idTokenResult.claims.cellphoneNumber,
        email: idTokenResult.claims.email,
        idToken,
        lastName: idTokenResult.claims.lastName,
        middleName: idTokenResult.claims.middleName,
        motherLastName: idTokenResult.claims.motherLastName,
        name: idTokenResult.claims.name,
        rut: idTokenResult.claims.user_id,
        temporaryPassword: idTokenResult.claims.temporaryPassword,
        termsVersion: idTokenResult.claims.termsVersion,
        userType: idTokenResult.claims.userType,
      } : null;
    }));
  }

  public refreshSession(): Promise<void> {
    return this.angularFireAuth.currentUser.then((user) => {
      if (!user) {
        throw new Error('User is not logged in');
      }
      return user.getIdToken(true).then();
    });
  }

  // TODO: refactor all the methods below

  public login(username: string, password: string, userType: number): Promise<void> {
    switch (userType) {
    case UI_VIEW_TYPES.client:
      return this.loginClient(username, password);
    case UI_VIEW_TYPES.contractor:
      return this.loginContractor(username, password);
    case UI_VIEW_TYPES.admin:
      return this.loginHomeInAdmin(username, password);
    }
  }

  private loginHomeInAdmin(email: string, password: string): Promise<void> {
    const data = {email, password};
    const url = `${environment.BASE_BACKEND}/authentication/login/admin`;
    return this.http.post<{ token: string }>(url, data).toPromise()
      .then(async ({ token }) => await this.signInWithToken(token));
  }

  private loginClient(rut: string, password: string): Promise<void> {
    const data = {rut, password};
    const url = `${environment.BASE_BACKEND}/authentication/login/client`;
    return this.http.post<{ token: string }>(url, data).toPromise()
      .then(async ({ token }) => await this.signInWithToken(token));
  }

  private loginContractor(email: string, password: string): Promise<void> {
    const data = {email, password};
    const url = `${environment.BASE_BACKEND}/authentication/login/contractor`;
    return this.http.post<{ token: string }>(url, data).toPromise()
      .then(async ({ token }) => await this.signInWithToken(token));
  }

  public changePassword(data: {
    actualPassword: string;
    newPassword: string;
  }): Promise<void> {
    const url = `${environment.BASE_BACKEND}/authentication/change-password`;
    return this.http.post<{ token: string }>(url, data).toPromise()
      .then(async ({ token }) => await this.signInWithToken(token));
  }

  public recoverPassword(email: string): Promise<void> {
    const data = {email};
    const url = `${environment.BASE_BACKEND}/authentication/recover-password`;
    return this.http.post<void>(url, data).toPromise();
  }

  public logout(): Promise<void> {
    return this.angularFireAuth.signOut();
  }

  public onboardUser(rut: string, password: string, passwordConfirmation: string,
    cellphoneNumber: string, email: string): Promise<void> {
    const data = {password, passwordConfirmation, cellphoneNumber, email};
    const url = `${environment.BASE_BACKEND}/authentication/${rut}/onboard`;
    return this.http.post<{ token: string }>(url, data).toPromise()
      .then(async ({ token }) => await this.signInWithToken(token));
  }

  public getWriteStorageSignedUrl(rut: string, fileName: string,
    contentType: string, bucketScope: string, folderName: string, companyRut?: string) {
    const params = new HttpParams()
      .set('fileName', fileName)
      .set('bucketScope', bucketScope)
      .set('folderName', folderName)
      .set('contentType', contentType)
      .set('companyRut', companyRut);
    const url = `${environment.BASE_BACKEND}/authentication/${rut}/write-storage-url`;
    return this.http.get<{ url: string }>(url, { params }).toPromise();
  }

  public async getReadStorageSignedUrl(rut: string, fileLocationUrl: string) {
    const params = new HttpParams()
      .set('fileLocationUrl', fileLocationUrl);
    const url = `${environment.BASE_BACKEND}/authentication/${rut}/read-storage-url`;
    return this.http.get<{ url: string }>(url, { params }).toPromise();
  }

  public activateLiteClientAccount(liteClientId: number) {
    const url = `${environment.BASE_BACKEND}/authentication/activate-account/${liteClientId}`;
    return this.http.patch<{ success: boolean }>(url, null).toPromise();
  }

  public clientLoginAsAdmin(clientRut: string, adminRut: string) {
    const url = `${environment.BASE_BACKEND}/authentication/login/impersonate/client/${clientRut}?rut=${adminRut}`;
    return this.http.get<{
      success: boolean;
      data: {
        token: string;
      }
    }>(url).toPromise();
  }

  public async signInWithToken(token: string): Promise<void> {
    await this.angularFireAuth.signInWithCustomToken(token);
  }
}
