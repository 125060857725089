/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { IReferralsListResponse } from '@interfaces/referral.interface';
import { IReferredRequest, IReferrerRequest } from '@interfaces/referred-program.interface';
import { HttpService } from '@services/http/http.service';
import { clean } from 'rut.js';

enum ReferralStatus {
  ADDED = 'ADDED',
  IN_MANAGEMENT = 'IN_MANAGEMENT',
  CREATED = 'CREATED',
  SUCCESSFUL = 'SUCCESSFUL',
  PAID = 'PAID',
}

@Injectable()
export class ReferredService {

  private referralStatuseToBusinessConcept = {
    ADDED: 'INGRESADO',
    IN_MANAGEMENT: 'EN GESTIÓN',
    CREATED: 'CREADO',
    SUCCESSFUL: 'EXITOSO',
    PAID: 'PAGADO',
  };

  constructor(
    private http: HttpService
  ) { }

  sendReferred(source: string, referred: IReferredRequest, referrer: IReferrerRequest): Promise<void> {
    const url = `${environment.BASE_BACKEND}/referrals`;
    referred.rut = clean(referred.rut);
    referrer.rut = clean(referrer.rut);
    return this.http.post(url, { source, referred, referrer }, null).toPromise() as Promise<void>;
  }

  // TODO: Review why a mockup is passed and add return type
  getReferrals(): Promise<IReferralsListResponse> {
    const url = `${environment.BASE_BACKEND}/referrals`;
    return this.http.get(url, null).toPromise() as Promise<IReferralsListResponse>;
  }

  convertReferralStatusToBusinessConcept(referralStatus: string): string {
    switch(referralStatus) {
    case ReferralStatus.ADDED:
      return this.referralStatuseToBusinessConcept.ADDED;
    case ReferralStatus.IN_MANAGEMENT:
      return this.referralStatuseToBusinessConcept.IN_MANAGEMENT;
    case ReferralStatus.CREATED:
      return this.referralStatuseToBusinessConcept.CREATED;
    case ReferralStatus.SUCCESSFUL:
      return this.referralStatuseToBusinessConcept.SUCCESSFUL;
    case ReferralStatus.PAID:
      return this.referralStatuseToBusinessConcept.PAID;
    default:
      return 'NO DEFINIDO';
    }

  }
}
