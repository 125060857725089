import { Injectable } from '@angular/core';

import safeStringify from 'fast-safe-stringify';

import { environment } from '@env';
import { IMockup } from '@interfaces/http.interface';
import { HttpService } from '@services/http/http.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorReportingService {
  constructor(
    private http: HttpService,
  ) {
  }

  public log(location: string, step: string, data: any): void {
    if (environment.ENV === 'production') {
      this.http.post(`${environment.BASE_CLOUD_FUNCTIONS}/errorReporting`, {
        filePath: location,
        functionName: step,
        message: `Error in ${location} at ${step}: ${data?.toString ? data?.toString() : ''} ${safeStringify(data)}`,
      }, {} as IMockup).toPromise();
    } else {
      console.error(`Error in ${location} at ${step}`, data);
    }
  }
}
