import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { throwError } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { environment } from '@env';
import { AuthService } from '@services/authentication/auth.service';

@Injectable()
export class HttpInterceptorProvider implements HttpInterceptor {
  private whitelist = [
    'authentication/login/admin',
    'authentication/login/client',
    'authentication/login/contractor',
    'storage.googleapis.com/homein-public-uploads',
    'storage.googleapis.com/homein-private-uploads',
    'storage.googleapis.com/homein-prod-public-uploads',
    'storage.googleapis.com/homein-prod-private-uploads',
    'errorReporting'
  ];

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler) {
    return this.authService.getSession().pipe(take(1), switchMap((session) => {
      if (session && !this.whitelist.some((prefix) => request.url.indexOf(prefix) > -1)) {
        if (request.url.startsWith(environment.BASE_CLOUD_FUNCTIONS)) {
          request = request.clone({setHeaders: {Authorization: `Bearer ${session.idToken}`}});
        } else {
          request = request.clone({setHeaders: {Authorization: session.idToken}});
        }
      }
      return next.handle(request).toPromise().catch(async (error) => {
        if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.Unauthorized) {
          if (session) {
            await this.authService.refreshSession().catch((error) => {
              console.error('HttpInterceptorProvider.refreshSession', error);
              this.authService.logout();
              this.router.navigate(['/login']);
            });
          }
        }
        return throwError(error).toPromise();
      });
    }));
  }
}
