import { IEnvironment } from 'src/app/common/interfaces/environment.interface';

export const environment: IEnvironment = {
  ENV: 'dev',
  BASE_BACKEND: 'https://dev-api.homein.cl',
  BASE_CLOUD_FUNCTIONS: 'https://us-central1-homein-dev-qa.cloudfunctions.net',
  CALENDLY_URL: 'https://calendly.com/alfredo-vidueira/30min',
  GOOGLE_ANALYTICS_ID: 'G-ZFHKTTDF6W',
  GOOGLE_TAG_MANAGER_ID: '',
  USING_MOCKS: false,
  MOCK_SUCCESS_RATE: 1,
  MOCK_DELAY_MILLISECONDS: 1000,
  FIREBASE_CONFIG: {
    apiKey: 'AIzaSyAxrqJV2haMaohkxQPd2yPo9R_J3SRAPW0',
    appId: '1:679152664148:web:59a19f54a81af6629fb7c1',
    authDomain: 'homein-dev-qa.firebaseapp.com',
    projectId: 'homein-dev-qa',
    storageBucket: 'homein-dev-qa.appspot.com',
  },
  WHATSAPP_PHONE_NUMBER: '56937100015'
};
